import React from "react";
import { Options } from "@inweb/viewer-core";
import { ConfigService } from "./services";
import UserStorage from "./services/UserStorage";
import ClientFactory from "./ClientFactory";

export const AppContext = React.createContext();

export class AppContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      config: null,
      project: null,
      options: new Options(),
    };
  }

  get user() {
    return this.state.user;
  }

  get config() {
    return this.state.config;
  }

  get options() {
    return this.state.options;
  }

  get project() {
    return this.state.project;
  }

  async loadConfig() {
    const config = await ConfigService.getConfig();
    this.setState({ config });
    return config;
  }

  async loginWithToken(token) {
    await this.loadConfig();
    const client = ClientFactory.get();
    const user = await client.signInWithToken(token);
    this.setUser(user, false);
    return user;
  }

  async loginFromStorage() {
    const token = UserStorage.getToken();
    if (token) await this.loginWithToken(token);
  }

  async login(email, password, rememberMe) {
    await this.loadConfig();
    const client = ClientFactory.get();
    const user = await client.signInWithEmail(email, password);
    this.setUser(user);
    UserStorage.setToken(user.token, rememberMe);
  }

  logout() {
    this.setUser();
    UserStorage.removeToken();
  }

  setUser(user) {
    if (user) this.options.data = user.customFields;
    this.setState({ user });
  }

  setProject(project) {
    this.setState({ project });
  }

  async saveUserSettings(optionsData) {
    this.options.data = optionsData;
    this.user.customFields = { ...this.user.data.customfields, ...this.options.data };
    const user = await this.user.save();
    this.setState({ user });
  }

  render() {
    return <AppContext.Provider value={{ app: this }}>{this.props.children}</AppContext.Provider>;
  }
}
